
const Selector = ({countryJson,setCountry}) => {
  
    return (
        <div className="selector-container">
        <div>
            <select onChange={(e) => setCountry(e.target.value)}>
                {countryJson.map((country, index) =>
                    <option key={index} value={country.Slug}>
                        {country.Country}
                    </option>
                )}
            </select>
        </div>
        </div>
    );
};

export default Selector;